import '../app/vendors/css/tables/datatable/dataTables.bootstrap5.min.css';
import '../app/vendors/css/tables/datatable/responsive.bootstrap5.min.css';
import '../app/vendors/css/tables/datatable/buttons.bootstrap5.min.css';
import '../app/vendors/css/tables/datatable/rowGroup.bootstrap5.min.css';
import '../app/vendors/css/tables/datatable/select.dataTables.min.css';
import '../app/vendors/css/tables/datatable/extensions/dataTables.checkboxes.css';

import jszip from 'jszip';
import pdfmake from 'pdfmake';
import DataTable from 'datatables.net-bs5';
import 'datatables.net-buttons-bs5';
import 'datatables.net-buttons/js/buttons.colVis.mjs';
import 'datatables.net-buttons/js/buttons.html5.mjs';
import 'datatables.net-buttons/js/buttons.print.mjs';
import 'datatables.net-responsive-bs5';
import 'datatables.net-rowgroup-bs5';