(function ($) {
    $.fn.codeScanner = function (options) {
        let settings = $.extend({}, $.fn.codeScanner.defaults, options);

        return this.each(function () {
            let pressed = false;
            let chars = [];
            let input = $(this);

            $(window).keypress(function (e) {
                let keycode;

                if (e.which) {
                    keycode = e.which;
                } else {
                    keycode = e.keyCode;
                }

                let conditionOne = keycode >= 65 && keycode <= 90;
                let conditionTwo = keycode >= 97 && keycode <= 122;
                let conditionThree = keycode >= 48 && keycode <= 57;

                if (conditionOne || conditionTwo || conditionThree) {
                    chars.push(String.fromCharCode(e.which));
                }

                if (pressed === false) {
                    setTimeout(function () {
                        if (chars.length >= settings.minEntryChars) {
                            let barcode = chars.join('');
                            settings.onScan(input, barcode);
                        }

                        chars = [];
                        pressed = false;
                    }, settings.maxEntryTime);
                }
                pressed = true;
            });

            $(this).keypress(function (e) {
                if (e.which === 13) {
                    e.preventDefault();
                }
            });

            return $(this);
        });
    };

    $.fn.codeScanner.defaults = {
        minEntryChars: 5,
        maxEntryTime: 500,
        onScan: function ($element, barcode) {
            $element.val(barcode);
        }
    };
})(jQuery);